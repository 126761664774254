import { memo } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

const Dashboard = (props) => {

	return (
		<>
			<Layout>
				<Row>
					<Col md={12}>
						<Card>
							<Card.Header>Dashboard</Card.Header>

							<Card.Body>
								<p>
									Dashboard will come here
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default memo(Dashboard)