import { memo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import { useNavigate } from "react-router-dom"
import GoogleAuthCallbackSkeleton from './components/skeleton/callback_skeleton'

const GoogleAuthCallback = (props) => {

	const { fetchGoogleAccounts, addGoogleAccounts } = props
	const [searchParams, ] = useSearchParams()
	const code = searchParams.get('code') || null
	const navigate = useNavigate()
	const [isPageLoading, setIsPageLoading] = useState(true)
	const [accounts, setAccounts] = useState([])
	const [selectedAccounts, setSelectedAccounts] = useState([])

	useEffect( () => {
		if(!code) return

		fetchGoogleAccounts({ code }).then(response => {
			setIsPageLoading(false)
			if(response.data.success) {
				setAccounts(response.data.accounts)

				if(!response.data.accounts) navigate('/google/accounts')
			}

		if(!response.data.success) navigate('/google/accounts')
		}).catch(error => setIsPageLoading(false))

	}, [code, fetchGoogleAccounts, navigate])

	const getAccountName = item => item.ad_account_name
	const getAccountId = item => item.ad_account_id
	const addAccounts = () => {

		if(selectedAccounts.length > 0) {
			addGoogleAccounts(selectedAccounts).then(response => {
				if(response.data.success) {
					// navigate('/google/accounts')
					window.location.href = '/google/accounts'
				}
			}).catch(error => console.log(error))
		}
	}

	return (
		<>
			<Layout>
				<Row>
					<Col md={{span: 8, offset: 2}}>
						
						{!isPageLoading &&
							<Card>
								<Card.Header>Add Google Accounts </Card.Header>

								<Card.Body>
									<Form>
										<Row>
											<Col md={6}>
												<Form.Group className="mb-4">
													<Select placeholder="Select Accounts" isMulti menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} defaultValue={selectedAccounts} options={accounts} getOptionLabel={getAccountName} getOptionValue={getAccountId} onChange={setSelectedAccounts} closeMenuOnSelect={false} />
												</Form.Group>
											</Col>
										</Row>

										<Button variant="primary" type="button" onClick={addAccounts}>Add</Button>	
									</Form>
								</Card.Body>
								
							</Card>
						}

						{isPageLoading &&
							<GoogleAuthCallbackSkeleton />
						}
					</Col>
				</Row>
			</Layout>
		</>
	)
}

export default memo(GoogleAuthCallback)