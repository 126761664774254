import { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Layout from 'layout'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import 'bootstrap-daterangepicker/daterangepicker.css'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment-timezone'
import NegativeKeywordsSkeleton from './components/skeleton/negative_keywords_skeleton'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort, Filter } from '@syncfusion/ej2-react-grids'

moment.tz.setDefault("America/New_York")

const NegativeKeywords = (props) => {

	let gridInstance;
	const { negative_keywords_data, getNegativeKeywords, default_account } = props
	const [isPageLoading, setIsPageLoading] = useState(true)
	const [page, setPage] = useState(1)
	const [totalRows, setTotalRows] = useState(0)
	const [startDate, setStartDate] = useState(moment().subtract('7', 'days').format('MM/DD/YYYY'))
	const [endDate, setEndDate] = useState(moment().subtract(1, 'day').format('MM/DD/YYYY'))
	const [sortBy, setSortBy] = useState(null)
	const [sortDirection, setSortDirection] = useState(null)
	const [gridKey, setGridKey] = useState(Date.now())
	const dispatch = useDispatch()
	const filterSettings: any = { 
		type: 'Menu',
		operators: {
			stringOperator: [
				{ value: 'startswith', text: 'Starts With' },
				{ value: 'endswith', text: 'Ends With' },
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'contains', text: 'Contains' },
		 		{ value: 'doesnotcontain', text: 'Does Not Contain' },
			],
			numberOperator: [
				{ value: 'equal', text: 'Equal' },
		 		{ value: 'notequal', text: 'Not Equal' },
		 		{ value: 'greaterthan', text: 'Greater Than' },
		 		{ value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
		 		{ value: 'lessthan', text: 'Less Than' },
		 		{ value: 'lessthanorequal', text: 'Less Than Or Equal' },
			],
		}
	}
	const [filterColumns, setFilterColumns] = useState([])

	const fetchNegativeKeywordsData =  useCallback(() => {
		// setIsPageLoading(true)
		
		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		const account_id = default_account.id
		const sort_by = sortBy
		const sort_direction = sortDirection
		const filters = filterColumns

		getNegativeKeywords({ page, account_id, from_date, to_date, sort_by, sort_direction, filters }).then(response => {
			setIsPageLoading(false)

			if(response.data.success) {
				setTotalRows(response.data.result.pagination.total_count)
				dispatch({
					type: 'UPDATE_NEGATIVE_KEYWORDS_DATA_OBJ',
					payload: response.data,
				})
				setGridKey(Date.now())
			}
		}).catch(err => setIsPageLoading(false))
	}, [getNegativeKeywords, page, startDate, endDate, sortBy, sortDirection, filterColumns, default_account, dispatch])

	useEffect(() => {
		if(default_account) {
			fetchNegativeKeywordsData()
		}
	}, [fetchNegativeKeywordsData, default_account])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = negative_keywords_data
		}
	}, [gridInstance, negative_keywords_data, gridKey])

	const handleDateChange = (event, picker) => {
		setStartDate(picker.startDate.format('MM/DD/YYYY'))
		setEndDate(picker.endDate.format('MM/DD/YYYY'))
		setPage(1)
	}

	const showSpend = (item) => Number(item.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) 
	const dataStateChange = (args) => {
		if(args.action.requestType === 'sorting') {
			if(args.action.columnName && args.action.direction) {
				setSortBy(args.action.columnName)
				setSortDirection(args.action.direction)
			} else {
				setSortBy(null)
				setSortDirection(null)
			}

			setPage(1)

			return
		}

		if(args.action.requestType === 'filtering') {

			if(args.action.action === 'filter') {
				let updatedFilters = filterColumns.map(item => {
					
					if(item.field === args.action.currentFilterObject.field) {
						return { ...item, field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value }
					}

					return item
				})

				let filteredFilterColumns = updatedFilters.filter(item => item.field === args.action.currentFilterObject.field)
				if(filteredFilterColumns.length === 0) {
					updatedFilters.push({field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value})
				}

				if(updatedFilters.length > 0) {
					setFilterColumns(updatedFilters)
				} else {
					setFilterColumns(result => [...result, {field: args.action.currentFilterObject.field, operator: args.action.currentFilterObject.operator, value: args.action.currentFilterObject.value}])
				}
			}

			if(args.action.action === 'clearFilter') {
				const indexToRemove = filterColumns.findIndex(item => item.field === args.action.currentFilterObject.properties.field)
				let updatedFilters = filterColumns.filter((item, index) => index !== indexToRemove)
				setFilterColumns(updatedFilters)
			}

			setPage(1)

			return
		}
	}
	
	
	return (
		<>
			<Layout>
				{!isPageLoading &&
					<>
						<Row>
							<Col md={3}>
								<Form.Group className="mb-4">
									<Form.Label><strong>Dates</strong></Form.Label>
									<DateRangePicker onApply={handleDateChange} initialSettings={{ startDate: startDate, endDate: endDate, ranges: {
							            Today: [moment().toDate(), moment().toDate()],
							            Yesterday: [
							              moment().subtract(1, 'days').toDate(),
							              moment().subtract(1, 'days').toDate(),
							            ],
							            'Last 7 Days': [
							              moment().subtract(7, 'days').toDate(),
							              moment().subtract(1, 'day').toDate(),
							            ],
							            'Last 30 Days': [
							              moment().subtract(30, 'days').toDate(),
							              moment().subtract(1, 'day').toDate(),
							            ],
							            'This Month': [
							              moment().startOf('month').toDate(),
							              moment().endOf('month').toDate(),
							            ],
							            'Last Month': [
							              moment().subtract(1, 'month').startOf('month').toDate(),
							              moment().subtract(1, 'month').endOf('month').toDate(),
							            ],
						          } }}>
										<input type="text" className="form-control" />
									</DateRangePicker>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Card>
									<Card.Header>Negative Keyword Tool</Card.Header>

									<Card.Body>
										<Row className="mb-3">
											<Col md={12}>

												<div className='control-pane'>
													<div className='control-section'>
														<GridComponent height={400} ref={grid => gridInstance = grid}  allowFiltering={true} allowSorting={true} enableStickyHeader={true}  frozenColumns={2} loadingIndicator={{ indicatorType: 'Shimmer' }} filterSettings={filterSettings} dataStateChange={dataStateChange}>
															<ColumnsDirective>
												            	<ColumnDirective field='campaign_name' width='420' headerText='Campaign Name' />
												            	<ColumnDirective field='adgroup_name' width='210' headerText='Ad Group Name' />
												            	<ColumnDirective field='search_term' width='200' headerText='Search Term' />
												            	<ColumnDirective field='search_term_match_type' width='220' headerText='Search Term Match Type' />
												            	<ColumnDirective field='keyword' width='220' headerText='Keyword' />
												            	<ColumnDirective field='keyword_match_type' width='220' headerText='Keyword Match Type' />
												            	<ColumnDirective field='clicks' width='200' headerText='Clicks' />
												            	<ColumnDirective field='impressions' width='200' headerText='Impressions' />
												            	<ColumnDirective field='spend' width='200' headerText='Spend' template={showSpend} />
												        	</ColumnsDirective>
												        	<Inject services={[Freeze, Sort, Filter]}/>
														</GridComponent>
													</div>
												</div>
											</Col>
										</Row>

										<Row>
											<Col md={12}>
												<div className="text-center">
													<PaginationControl page={page} total={totalRows} changePage={(page) => setPage(page)} limit={100} ellipsis={1} />
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</>
				}

				{isPageLoading && 
					<NegativeKeywordsSkeleton />
				}
			</Layout>
		</>
	)
}

export default memo(NegativeKeywords)