import { combineReducers } from 'redux'
import googleAuthReducer from './googleAuthReducer'
import googleAccountsReducer from './googleAccountsReducer'
import searchTermReducer from './searchTermReducer'
import assetReducer from './assetReducer'

const initialState = {
	app_name: 'Reporting Tool',
	show_loader: false,
	authenticated: !(localStorage.getItem('token') === null || localStorage.getItem('token') === 'null' || localStorage.getItem('token') === ''),
	user: {},
	accounts: [],
	default_account: null,
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		
		case 'SHOW_LOADER':
			return {
				...state,
				show_loader: true,
			}

		case 'HIDE_LOADER':
			return {
				...state,
				show_loader: false,
			}

		case 'USER_LOGGED':
			
			return {
				...state,
				authenticated: true,
				user: action.payload.user,
				accounts: [...action.payload.accounts],
				default_account: action.payload.default_account ?? null,
			}

		case 'USER_LOGGED_OUT':
			return {
				...state,
				authenticated: false,
				user: {},
				default_account: null,
			}

		case 'UPDATE_LOGGED_USER':
			
			return {
				...state,
				user: action.payload.user,
				accounts: [...action.payload.accounts],
				default_account: action.payload.default_account ?? null,
			}

		case 'UPDATE_SELECTED_ACCOUNT':
			return {
				...state,
				default_account: action.payload.default_account,
			}

		case 'USER_PROFILE_UPDATE':
			return {
				...state,
				user: { ...action.payload.user }
			}

		default:
			return state
	}
}

const rootReducer = combineReducers({reducer, googleAuthReducer, searchTermReducer, assetReducer, googleAccountsReducer })

export default rootReducer