import Login from 'containers/loginContainer'
import GoogleAuth from 'containers/googleAuthContainer'
import GoogleAuthCallback from 'containers/googleAuthCallbackContainer'
import GoogleAccounts from 'containers/googleAccountsContainer'
import NegativeKeywords from 'containers/negativeKeywordsContainer'
import Dashboard from 'containers/dashboardContainer'
import Assets from 'containers/assetContainer'

export const routes = [
	{
		path: '/',
		element: <Login />,
		auth: true,
	},
	{
		path: 'login',
		element: <Login />,
		auth: true,
	},
	{
		path: 'dashboard',
		element: <Dashboard />,
		protected: true,
	},
	{
		path: 'google/login',
		element: <GoogleAuth />,
		protected: true,
	},
	{
		path: 'google/accounts',
		element: <GoogleAccounts />,
		protected: true,
		name: 'Google Accounts',
	},
	{
		path: 'google/callback',
		element: <GoogleAuthCallback />,
		protected: true,
	},
	{
		path: 'searchterms/negative',
		element: <NegativeKeywords />,
		protected: true,
	},
	{
		path: 'assets',
		element: <Assets />,
		protected: true,
	},
]
