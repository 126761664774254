import { memo, useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from "react-router-dom"
import { useAuth } from "context/AuthProvider"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { useDispatch } from 'react-redux'

const Header = (props) => {

	const { authenticated, accounts, user, fetchUser, submitLogout, default_account } = props
 	const navigate = useNavigate()
	const { logout } = useAuth()
	const accountSelector = useRef()
	const [selectedAccount, setSelectedAccount] = useState(default_account)
	const [accountSelectorKey, setAccountSelectorKey] = useState(Date.now())
	const dispatch = useDispatch()

	useEffect(() => {
    if(authenticated && !Object.keys(user).length) {
    	fetchUser().then(response => {
      	if(response.data.success) {
      		setSelectedAccount(response.data.default_account)	
      		
      		/*if(accountSelector.current) {
      			accountSelector.current.setValue(response.data.default_account)
      		}*/

      		setAccountSelectorKey(Date.now())
      	}
      })
    }
  }, [authenticated, fetchUser, user])

	const logoutUser = () => {
		submitLogout().then(response => {
			logout()
			navigate("/login")
		})	
	}

	const getAccountName = item => item.ad_account_name
	const getAccountId = item => item.ad_account_id

	const setAccountSelected = (item) => {
		setSelectedAccount(item)

		dispatch({
			type: 'UPDATE_SELECTED_ACCOUNT',
			payload: { default_account: item },
		})

		navigate('/dashboard')
	}

	return (
		<>
			{authenticated &&
			<Navbar className="main-header bg-light" expand="lg" sticky="top">
				<Container fluid>
					<Navbar.Brand className="d-xxl-none" href="/">Search AI</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<>
							
							<Navbar.Collapse id="navbarScroll">
								<Nav className="me-auto my-2 my-lg-0" navbarScroll>
									<Select ref={accountSelector} key={accountSelectorKey} placeholder="Select Account" menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} defaultValue={selectedAccount} options={accounts} getOptionLabel={getAccountName} getOptionValue={getAccountId} onChange={setAccountSelected} closeMenuOnSelect={true} />
								</Nav>
								<Nav className="d-flex">
									<NavDropdown title={`${user.first_name} ${user.last_name}`}>
										<NavDropdown.Item as={Link}  onClick={logoutUser}>Logout</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>
						</>
				</Container>
			</Navbar>
			}

			{!authenticated &&
				<Navbar className="main-header bg-light" expand="lg" sticky="top">
				<Container>
					<Navbar.Brand href="/">Search AI</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
				</Container>
			</Navbar>
			}
		</>
	)
}

export default memo(Header)